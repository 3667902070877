import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .headingHead{
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
`;

export const DescriptionText = styled(Typography)`
  font-size: 18px;
  line-height: 1.223;
  color: #C0C0C0;
  margin-top: 24px;
`;

export const VideosWrapper = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
  }
  @media (min-width: 992px){
    margin-left: -36px;
    margin-right: -36px;
  }
`;

export const Col = styled(Box)`

  &:first-child{

    @media (min-width: 768px){
      width: 48.6%;
    }
  }

  @media (min-width: 768px){
    padding-left: 15px;
    padding-right: 15px;
    width: 51.1%;
  }
  @media (min-width: 992px){
    padding-left: 36px;
    padding-right: 36px;
  }
`;