import { styled, Box } from '@mui/material';

export const LaningPageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  margin: -30px -20px;
  background-color: #000;

  @media (min-width: 768px){
    margin-left: -40px;
    margin-right: -40px;
  }
`;

export const CustomDivider = styled(Box)`
  margin: 70px auto;
  height: 1px;
  background-image: linear-gradient(to right,  rgba(55,55,55,0) 0%,rgba(55,55,55,1) 50%,rgba(55,55,55,0) 100%);
  width: 100%;
  max-width: 1110px;
`;
