import { DescriptionPara, MainHeading, Wrapper } from "./style";

const SectionMainHeader = ({ heading, description, size }: any) => (
  <Wrapper
    sx={{
      maxWidth: size
    }}
  >
    <MainHeading
      variant="h1"
    >
      {heading}
    </MainHeading>
    {description &&
      <DescriptionPara
        variant="body1"
      >
        {description}
      </DescriptionPara>
    }
  </Wrapper>
);

export default SectionMainHeader;