import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const PresentationSection = ({pTop, pBottom}: any) => (
  <Section
    className="PresentationSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <TwoColumnsGrid sameheight="false">
        <CardBox>
          <FeaturesListColumn heading="The Old Way">
            <FeaturesList>
              <ListItem>
                <ListItemText
                  primary="Creating a digital course is time-consuming, expensive & high-effort"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Diversifying your income streams requires technical sophistication"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Providing support for learners doesn’t scale as your buried in emails & phone calls"
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
        <CardBox>
          <FeaturesListColumn heading="The New Way" status="active">
            <FeaturesList>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Effortlessly create a course with our intuitive, simple to use tools, enhanced with AI"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Sell your course online & get paid instantly"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Provide a personalized AI chatbot trained on your course materials to each of your students"
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default PresentationSection;