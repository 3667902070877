import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const HeadingHead = styled(Box)`
  margin-bottom: 36px;
`;

export const SectionHeading = styled(Typography)`
  font: 800 30px/1.167 'Playfair Display', 'Times New Roman', Times, serif;

  @media (min-width: 768px){
    font-size: 35px;
  }
  @media (min-width: 992px){
    font-size: 40px;
  }
  @media (min-width: 1200px){
    font-size: 48px;
  }

  strong{
    color: #E3C050;
  }
`;

export const DescriptionText = styled(Typography)`
  color: #C0C0C0;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 30px;
`;