import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 16px 24px;
`;

export const ImageHolder = styled(Box)`
  margin: -16px -24px 16px;
  overflow: hidden;
  border-radius: 11px;
  
  img{
    border-radius: inherit;
    width: 100%;
    height: auto;
  }
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const Description = styled(Typography)`
  line-height: 1.5;
  color: #C0C0C0;
  font-size: 16px;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const Hr = styled(Divider)`
  border: solid rgba(227, 229, 232, 0.16);
  border-width: 1px 0 0;
  margin-top: 16px;
  margin-bottom: 16px;
`;