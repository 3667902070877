import { styled, Box, Button, Link, Typography } from '@mui/material';

export const ToolbarWrapper = styled("div")`
  .Toolbar {
    column-gap: 15px;
  }

  .sidebar-opener-button {
    .sidebar-wrapper-active & {
      border-color: #e3c050;

      .opener-state {
        display: none;
      }

      .closer-state {
        display: block;
      }
    }

    .closer-state {
      display: none;
      color: #fff;
    }
  }

  .sidebar-opener-button,
  .apps-opener > button {
    display: block;
    color: #8c8e95;
    font-size: 8px;
    line-height: 1;
    font-weight: 700;
    min-width: 1px;
    padding: 0;
    width: 38px;
    height: 38px;
    transition: color 0.3s ease;
    border: 1px solid transparent;
    border-radius: 8px;

    &:hover {
      color: #fff;

      .sob-icn {
        color: inherit;
      }
    }

    .sob-icn {
      color: #c0c0c0;
      display: block;
      margin-bottom: 4px;
      line-height: 0;
      transition: color 0.3s ease;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .user-actions-wrap {
    flex-grow: 1;
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: flex-end;
  }

  .user-dropdown {
    > button {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      overflow: hidden;
      padding: 0;
      border: 0;
      background-color: #373737;
      position: relative;

      img {
        border-radius: inherit;
        border-radius: inherit;
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .icn-placeholder {
      line-height: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .actions-divider {
    border-color: #373737;
  }
`;

export const Wrapper = styled(Box)`
  background-color: #1F1F1F;
  padding: 5px 8px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid rgb(43, 43, 43);

  @media (min-width: 768px){
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const NavWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
`;

export const Separator = styled(Box)`
  width: 1px;
  height: 24px;
  background-color: #373737;
`;

export const ButtonNav = styled(Button)`
  color: #c0c0c0;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  min-width: 1px;
  padding: 3px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 8px;
  position: relative;
  padding: 0 !important;

  &:hover{
    color: #fff !important;
    background-color: transparent !important;
  }

  &.sidebar-opener--close{
    color: #fff !important;
    background-color: #131313 !important;
    border-color: #E3C050 !important;

    &:before{
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: default;
    }

    .icn-close{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: inherit;
      background-color: #131313;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .icn-close{
    display: none;

    .MuiSvgIcon-root{
      width: 18px;
      height: 18px;
    }
  }

  &,
  .icn-wrap{
    display: block;
  }

  .MuiSvgIcon-root{
    width: 40px;
    height: 40px;
  }
}
`;

export const Logo = styled(Link)`
  line-height: 0;
  display: block;
  margin-left: 10px;
  
  img{
    max-width: 100%;
    height: auto;
  }
`;

export const ActionsWrapper = styled(Box)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 5px;

  @media (min-width: 768px){
    gap: 12px;
  }
`;

export const ProfileDropdown = styled(Box)`
  position: relative;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  border: 1px solid #E3C050;
  padding: 0;
  background-color: transparent;
  display: block;
  cursor: pointer;

  @media (min-width: 768px){
    width: 46px;
    height: 46px;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
`;

export const LoginButton = styled(`span`)`
  color: #c0c0c0;
  font-size: 10px;
  font-weight: 600;
  line-height: 0;
  min-width: 1px;
  background-color: transparent;
  padding: 3px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 11px;

  @media (min-width: 768px){
    height: 50px !important;
    width: 50px !important;
  }

  &:hover{
    color: #e9e9e9;
    background-color: #1f1f1f;
    border-color: #E3C050;
  }

  .icn-wrap{
    display: block;
    margin-top: -5px;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 19px;
  }
`;