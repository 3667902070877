import CardBox from "components/CardBox";
import { Col, Heading, ListWrapper, Wrapper } from "./style";
import { ListItem, ListItemText } from "@mui/material";

const ComparisonListColumns = ({}: any) => (
  <CardBox>
    <Wrapper>
      <Col>
        <Heading
          variant="h3"
        >
          Under consideration
        </Heading>
        <ListWrapper>
          <ListItem>
            <ListItemText
              primary="Host on your domain"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Send emails from your domain"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Customized to your colours"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Customized to your typography"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Customized to your logo & favicon"
            />
          </ListItem>
        </ListWrapper>
      </Col>
      <Col>
        <Heading
          variant="h3"
        >
          In progress
        </Heading>
        <ListWrapper>
          <ListItem>
            <ListItemText
              primary="AI Personalization"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI Tutoring"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI Images"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI Videos"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI Scripts"
            />
          </ListItem>
        </ListWrapper>
      </Col>
    </Wrapper>
  </CardBox>
);

export default ComparisonListColumns;