import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { AnchorLink, ButtonDropdownWrapper, ButtonOpener, CourseMenu, DescriptionWrapper, TextPara, LogoWrapper } from './style';
import { UpDownIcon } from 'components/Icons/UpDownIcon';
import { TickIcon } from 'components/Icons/TickIcon';

export default function SelectorDropdown({label, id} : any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ButtonDropdownWrapper>
      <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        <LogoWrapper>
          {label.split('')[0]}
        </LogoWrapper>
        <span className="text-span">
          {label}
        </span>
        <i className='icn-wrap'>
          <UpDownIcon />
        </i>
      </ButtonOpener>
      <CourseMenu id={`${label}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <LogoWrapper>
            {label.split('')[0]}
          </LogoWrapper>
          <DescriptionWrapper className="DescriptionWrapper">
            <TextPara
              variant="body1"
            >
              {label}
            </TextPara>
          </DescriptionWrapper>
          <i className='tick-icn'>
            <TickIcon />
          </i>
        </MenuItem>
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}