import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";

export function useTranslate() {
  const cookies = new Cookies();
  const [translateCookies, setTranslateCookies] = useState(() =>
    cookies.get("googletrans")
  );

  useEffect(() => {
    cookies.set("googletrans", translateCookies)
  }, [translateCookies]);

  return [
    translateCookies,
    setTranslateCookies,
  ]
}

