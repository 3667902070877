import { Box, Chip, Divider, Typography } from "@mui/material";
import { ArticleBox, HomeContentWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { useGetCoursesQuery } from "api/courses";

export const Home = () => {
  const navigate = useNavigate();
  const { data } = useGetCoursesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <HomeContentWrapper>
      <Box textAlign={"center"}>
        <Typography
          variant="h1"
          className="hcwHeading"
        >
          Courses
        </Typography>
        <Typography
          className="hcwSubheading"
        >
          Challenging multi-step experiences
        </Typography>
      </Box>
      <Divider
        className="hcwDivider"
      />

      <Box
        className="hcwhcwBoxWrapper"
      >
        {data?.map((course: any, key: any) => (
          <ArticleBox
            key={key}
            className="ArticleBox"
            onClick={() => navigate("/course/" + course.slug + "/home")}
          >
            <Box
              className="artbBoxWrapper"
            >
              <Box
                className="artbartbBoxWrapper"
              >
                <img src={course?.img} height={"100px"} />
              </Box>
              <Typography
                variant="h3"
                className="artHeading"
              >
                {course?.title}
              </Typography>
              <Typography
                className="artSubheading"
              >
                {course?.description}
              </Typography>
            </Box>
            <Box
              className="artartartBoxWrapper"
            >
              {course?.tags.map((tag: any, keys: any) => (
                <Chip className="chip-tag" key={key + keys} label={tag} />
              ))}
            </Box>
          </ArticleBox>
        ))}
      </Box>
    </HomeContentWrapper>
  );
};
