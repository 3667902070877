import { styled, Box, Button, Link } from '@mui/material';
import Menu from '@mui/material/Menu';

export const ButtonDropdownWrapper = styled('div')`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const CourseMenu = styled(Menu)`

  > .MuiPaper-elevation{
    background-color: #131313 !important;
    border-color: #131313 !important;
    min-width: 167px;
    margin-top: 0;

    .MuiMenu-list{
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    .MuiMenuItem-root{
      color: #fff;
      text-transform: uppercase;
    }
  }
`;

export const ButtonOpener = styled(Button)`
  background-color: #2B2B2B;
  border-radius: 4px;
  border: 0;
  color: #EBD18A;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding: 8px 12px;

  &:hover{
    background-color: #454648;
    color: #EBD18A;
  }

  .icn-wrap,
  > .MuiSvgIcon-root{
    line-height: 0;
  }

  .icn-wrap{
    margin-right: -3px;
  }

  .MuiSvgIcon-root{
    width: 21px;
    height: 21px;
  }
`;

export const Anchor = styled(Link)`
  color: inherit;
  font: inherit;
  text-decoration: none;
  display: block;
  width: 100%;

  &:before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
`;