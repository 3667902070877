import { styled, Box } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  > div{
    width: 100%;

    @media (min-width: 576px){
      width: calc(50% - 16px);
    }
    @media (min-width: 992px){
      width: calc(33.333% - 16px);
    }
  }
`;