import { styled } from '@mui/material';

export const HomeContentWrapper = styled('div')`
  
  .hcwHeading{
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    margin: 0 0 24px;
    color: #fff;
  }

  .hcwSubheading{
    font-size: 18px;
    line-height: 22px;
  }

  .hcwDivider{
    border: solid #373737;
    border-width: 2px 0 0;
    margin: 40px auto 70px;
    width: 100%;
    max-width: 100px;
  }

  .hcwhcwBoxWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const ArticleBox = styled('div')`
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: #c0c0c0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #1f1f1f;

  @media (min-width: 576px){
    width: calc(50% - 23px);
  }
  @media (min-width: 992px){
    width: calc(33.333% - 23px);
  }
  @media (min-width: 1350px){
    width: calc(25% - 23px);
  }

  .artbBoxWrapper{
    flex-grow: 1;
  }

  .artbartbBoxWrapper{
    width: calc(100% + 40px);
    height: 250px;
    background-color: #373737;
    margin: -20px -20px 20px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .artHeading{
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 20px;
    color: #fff;
    font-weight: 700;
  }

  .artSubheading{
    margin-bottom: 20px;
  }

  .artartartBoxWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .chip-tag {
      border-radius: 4px;
      background-color: #E3C050;
      font-size: 12px;
      line-height: 1;
      color: #000;
      height: auto;
      padding: 5px 4px 7px;
      font-weight: 600;
    }
  }

  .sidebar-wrapper-active &{

    @media (min-width: 576px){
      width: calc(50% - 23px);
    }
    @media (min-width: 992px){
      width: calc(50% - 23px);
    }
    @media (min-width: 1200px){
      width: calc(33.333% - 23px);
    }
    @media (min-width: 1350px){
      width: calc(25% - 23px);
    }
  }
`;
