import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 24px;
  margin-top: 16px;
  margin-bottom: 36px;
`;

export const TextItem = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  line-height: 1.5;

  &.warning{

    &:before{
      background-color: #E3C050;
    }
  }

  &.primary{

    &:before{
      background-color: #0080FF;
    }
  }

  &.secondary{

    &:before{
      background-color: #C849F4;
    }
  }

  &:before{
    content: '';
    border-radius: 100%;
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: top;
    background-color: currentColor;
  }
`;