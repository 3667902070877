import { ButtonPrimary, CustomDivider, ExcerptText, PercentText, PriceText, TagWrapper, TypeHeading, Wrapper } from "./style";

const PriceColumn = ({type, excerpt, priceText, percentText, buttonLabel, children}: any) => (
  <Wrapper>
    <TagWrapper>
      <TypeHeading
        variant="h3"
      >
        {type}
      </TypeHeading>
    </TagWrapper>
    {excerpt &&
      <ExcerptText>
        {excerpt}
      </ExcerptText>
    }
    <PriceText
      variant="h4"
    >
      {priceText}
    </PriceText>
    {percentText &&
      <PercentText
        variant="caption"
      >
        {percentText}
      </PercentText>
    }
    <CustomDivider />
    {children &&
      <>
        {children}
      </>
    }
    <ButtonPrimary>
      {buttonLabel}
    </ButtonPrimary>
  </Wrapper>
);

export default PriceColumn;