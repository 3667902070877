import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Field, FieldArray, useFormikContext } from "formik";
import { useDrag, useDrop } from "react-dnd";
import { useCallback } from "react";
import {
  FormRow,
  LabelHeading,
  FormField,
  LesButtonWrapper,
  LesRowWrapper,
  LesHeading,
  CustomCheckbox,
  CustomCheckboxLocked,
} from "./style";

export const SingleLesson = ({
  topicIndex,
  index,
  purchase,
  topicArrayHelper,
}: any) => {
  const { values, errors, touched, handleChange, setFieldValue }: any =
    useFormikContext();

  const [{ isDragging }, dragRef] = useDrag({
    type: `LESSON-${index}`,
    item: { topicIndex, values: values.tasks[index].topics[topicIndex] },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop(() => ({
    accept: `LESSON-${index}`,
    drop: (item: any) => {
      if (topicIndex !== item.topicIndex)
        swapLesson(item.topicIndex, topicIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const validationTitle = () => {
    try {
      if (
        touched.tasks &&
        touched.tasks[index]?.topics[topicIndex]?.title &&
        errors.tasks &&
        (errors as any).tasks[index]?.topics?.length > topicIndex
      )
        return true;
    } catch {}

    return false;
  };

  const validationVideo = () => {
    try {
      if (
        touched.tasks &&
        touched.tasks[index]?.topics[topicIndex]?.video &&
        errors.tasks &&
        (errors as any).tasks[index]?.topics?.length > topicIndex
      )
        return true;
    } catch {}

    return false;
  };

  const swapLesson = useCallback(
    (x: number, y: number) => {
      const a = values.tasks[index].topics[x];
      const b = values.tasks[index].topics[y];

      setFieldValue(`tasks.${index}.topics.${x}.pos`, y);
      setFieldValue(`tasks.${index}.topics.${y}.pos`, x);
    },
    [values]
  );

  console.log(touched);

  return (
    <div ref={drop}>
      <div ref={dragRef}>
        <LesRowWrapper className="LesRowWrapper">
          {topicIndex > 0 && (
            <>
              <LesHeading variant="h2">Lesson</LesHeading>
              <button
                className="btn-remove"
                type="button"
                onClick={() => topicArrayHelper.remove(topicIndex)} // remove a friend from the list
              >
                <CloseIcon />
                REMOVE
              </button>
            </>
          )}
          <FormRow>
            <LabelHeading variant="h3">
              {`Lesson ${topicIndex + 1} Title:`}
            </LabelHeading>
            <FormField
              name={`tasks.${index}.topics.${topicIndex}.title`}
              onChange={(e: any) => {
                handleChange(e);
                setFieldValue(
                  `tasks.${index}.topics.${topicIndex}.slug`,
                  e.target.value.replaceAll(" ", "-")
                );
              }}
            />
            {validationTitle() && (
              <div>
                {(errors as any).tasks[index].topics[topicIndex]?.title}
              </div>
            )}
          </FormRow>
          <FormRow>
            <LabelHeading variant="h3">
              {`Lesson ${topicIndex + 1} Video:`}
            </LabelHeading>
            <FormField
              name={`tasks.${index}.topics.${topicIndex}.video`}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
            {validationVideo() && (
              <div>
                {(errors as any).tasks[index].topics[topicIndex]?.video}
              </div>
            )}
          </FormRow>
          {(topicIndex > 0 || index > 0) && purchase && (
            <FormRow>
              <CustomCheckboxLocked
                control={
                  <Checkbox
                    checked={
                      values?.tasks[index]?.topics[topicIndex]?.locked ||
                      !purchase
                    }
                    onChange={(e) => {
                      setFieldValue(
                        `tasks.${index}.topics.${topicIndex}.locked`,
                        e.target.checked
                      );
                    }}
                  />
                }
                label={purchase ? "Locked" : "Free"}
              />
            </FormRow>
          )}
        </LesRowWrapper>
        <LesButtonWrapper className="LesButtonWrapper">
          {values.tasks?.[index].topics.length - 1 === topicIndex && (
            <button
              type="button"
              onClick={() =>
                topicArrayHelper.insert(topicIndex + 1, {
                  pos: topicIndex + 1,
                  slug: "",
                  title: "",
                  video: "",
                  locked: false,
                })
              } // insert an empty string at a position
            >
              ADD LESSON
            </button>
          )}
        </LesButtonWrapper>
      </div>
    </div>
  );
};

export const Lesson = ({ index, purchase }: any) => {
  const { values }: any = useFormikContext();

  const sortedTopics = values.tasks[index].topics[0].pos
    ? values.tasks[index].topics.sort((a: any, b: any) => a.pos - b.pos)
    : values.tasks[index].topics;

  return (
    <FieldArray
      name={`tasks.${index}.topics`}
      render={(topicArrayHelper) => (
        <>
          {sortedTopics && sortedTopics.length > 0
            ? sortedTopics.map((topic: any, topicIndex: any) => (
                <LesRowWrapper className="LesRowWrapper" key={topicIndex}>
                  <SingleLesson
                    index={index}
                    topicIndex={topicIndex}
                    purchase={purchase}
                    topicArrayHelper={topicArrayHelper}
                  />
                </LesRowWrapper>
              ))
            : null}
        </>
      )}
    />
  );
};
