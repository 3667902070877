import CardBox from "components/CardBox";
import { Col, Header, HeaderHeading, ImageHolder, Wrapper, AlignBox, ItemHeading, Body, IconWrapper, ScrollWrapper } from "./style";
import Logo6ix from "../../assets/6ix-logos.svg"
import { TickIcon } from "components/Icons/TickIcon";
import { CrossIcon } from "components/Icons/CrossIcon";

const FeatureTableColumn = ({}: any) => (
  <CardBox>
    <ScrollWrapper>
      <Wrapper>
        <Header
          className="head"
        >
          <Col>
            <AlignBox>
              <HeaderHeading
                variant="h3"
              >
                Features & Capabilites
              </HeaderHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ImageHolder>
                <img src={Logo6ix} width={"76"} height={"30"} alt="6ix courses" />
              </ImageHolder>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <HeaderHeading
                variant="h3"
              >
                Other Tools
              </HeaderHeading>
            </AlignBox>
          </Col>
        </Header>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Intuitive Course Creation Tools
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Integrated Monetization Gateways
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Embedded AI
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Integration with 6ix Events
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Integration with 6ix Leads
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Integration with other 6ix Tools
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Comprehensive learner profiles
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Inbound investor meetings
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Data-driven investor reports
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Embed 3rd party content
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Easily publish updates
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Hosted on your domain
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Matches your brand
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Modern designs
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
      </Wrapper>
    </ScrollWrapper>
  </CardBox>
);

export default FeatureTableColumn;