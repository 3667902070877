import { Box, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import SectionHeader from "components/SectionHeader";
import FourColumnsGrid from "components/FourColumnsGrid";
import CardBox from "components/CardBox";
import IconColumn from "components/IconColumn";
import { SearchIcon } from "components/Icons/SearchIcon";
import { PencilIcon } from "components/Icons/PencilIcon";
import { EmailIcon } from "components/Icons/EmailIcon";
import { EnrollIcon } from "components/Icons/EnrollIcon";

const IconsSection = ({pTop, pBottom}: any) => (
  <Section
    className="IonsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader heading="How 6ix Courses Works" />
      <FourColumnsGrid>
        <CardBox>
          <IconColumn heading="Create your syllabus using our embedded AI" icon={<SearchIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Set your prices & publish your course" icon={<PencilIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Promote your course online & offline" icon={<EmailIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Get paid as people enroll" icon={<EnrollIcon />} />
        </CardBox>
      </FourColumnsGrid>
    </Wrapper>
  </Section>
);

export default IconsSection;