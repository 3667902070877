import "App.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AppRouter } from "routes";
import { store } from "store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderNew from "components/HeaderNew";

const App = () => {
  return (
    <BrowserRouter>
      <HeaderNew>
        <AppRouter />
      </HeaderNew>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme={"dark"}
        position="bottom-left"
      />
    </BrowserRouter>
  );
};

export default App;
