import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 45px;

  @media (min-width: 992px){
    margin-top: 70px;
  }

  .plyr__progress__marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #606060;
  }

  .video-wrap {
    overflow: hidden;
    position: relative;
    border-radius: 11px;
    width: 100%;
  }

  .video-wrap video {
    width: 100%;
    height: calc(100vh - 160px);
    object-fit: contain;
  }

  .plyr__control--overlaid {
      background-color: #000 !important;
      border: 5px solid #000;
      height: 100px;
      width: 100px !important;
  }
  
  .plyr__control--overlaid use {
      color: #d9d9d9;
  }
  
  .plyr__control--overlaid svg {
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
  }

  .plyr--video {
    border-radius: 0;
  }

  .plyr--video .plyr__controls {
    padding: 0;
    height: 44px;
    background-color: #000;
  }

  .plyr__progress input[type=range] {
    color: #E9E9E9;
  }

  .plyr__progress__buffer {
    background: var(--hms-ui-colors-primaryLight);
  }

  .vid-player-wrap {
    position: relative;
    width: 860px;
    max-width: 94vw;
  }

  .click-for-sound {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 12px;
    padding: 0 16px;
    height: 55px;
    border: none;
    border-radius: 40px;
    background-color: #131313;
    color: #E9E9E9;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    min-width: 55px;
    box-sizing: border-box;
  }

  .click-for-sound-text {
    display: none;
  }

  .click-for-sound img {
    width: 23px;
  }

  .video-wrap:hover .click-for-sound-text {
    display: block;
  }

  .plyr__controls__item .plyr__control,
  button.plyr__controls__item {
    height: 44px;
    width: 44px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }

  .plyr__controls__item .plyr__control:hover,
  button.plyr__controls__item:hover {
    background-color: var(--hms-ui-colors-primaryLight);
  }

  /* .plyr__control[data-plyr="pip"], .plyr__control[data-plyr="captions"]{
  display: none !important;
  } */
  .plyr__controls__item.plyr__volume {
    position: relative;
  }

  .plyr__controls__item.plyr__volume input {
    display: none;
    position: absolute;
    background-color: #13131396;
    text-align: center;
    padding: 22px 12px;
    width: 120px;
    border-radius: 0;
    left: 50%;
    transform: rotate(-90deg) !important;
    -webkit-transform: rotate(-90deg) !important;
    -moz-transform: rotate(-90deg) !important;
    -o-transform: rotate(-90deg) !important;
    -ms-transform: rotate(-90deg) !important;
    transform-origin: left;
    bottom: 50%;
    top: -20px;
    width: 110px !important;
    max-width: none;
    margin: 0;
    color: #e9e9e9;
  }

  .plyr__controls__item.plyr__volume:hover input {
    display: block;
  }

  .plyr__controls__item.plyr__menu {
    position: static;
  }

  .plyr__menu__container {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    margin: 0;
    bottom: 146px;
    border-radius: 11px;
    background-color: #131313;
    color: #8C8E95;
    font-size: 14px;
    padding: 4px;
  }

  .plyr__menu__container .plyr__control {
    width: 100%;
    margin: 0;
  }

  .speed-control-area {
    display: none;
    z-index: 1;
    border-radius: 11px;
    background-color: #131313;
    color: #8C8E95;
    font-size: 14px;
    padding: 4px;
    overflow: hidden;
    position: absolute;
    bottom: 16%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    white-space: nowrap;
  }

  .speed-control-area.visible {
    display: block;
  }

  .vid-light-text {
    color: #fff;
  }

  .speed-control-area .active {
    color: #fff !important;
  }

  .speed-control-area-row {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  .speed-control-area-num {
    margin-bottom: 4px;
  }

  .speed-control-area-num .x-btn.active {
    transform: scale(1.14);
    -webkit-transform: scale(1.14);
    -moz-transform: scale(1.14);
    -o-transform: scale(1.14);
    -ms-transform: scale(1.14);
    transform-origin: center;
  }

  .speed-control-area-num .x-btn {
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    color: inherit;
  }

  .speed-control-area-num .x-btn:hover {
    color: #fff !important;
  }

  .speed-control-area-time {
    background-color: var(--hms-ui-colors-primaryLight);
  }

  .display-none {
    display: none;
  }

  .markers-list {
    display: none;
    position: absolute;
    bottom: 38px;
    right: 0;
    z-index: 1;
    background-color: #000000bf;
    color: #fff;
    max-width: 270px;
    max-height: 70%;
    overflow-y: auto;
  }

  .markers-list.visible {
    display: block;
  }

  .markers-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .markers-list ul li {
    padding: 8px 12px;
    display: flex;
    font-weight: 600;
  }

  .markers-list-time {
    white-space: nowrap;
  }

  .markers-list ul li:hover {
    background-color: #000;
    cursor: pointer;
  }

  .markers-list-text {
    margin-left: 12px;
  }

  .plyr__tooltip {
    background-color: #000;
    color: #fff;
    font-size: 20px;
    max-width: none !important;
    width: fit-content;
  }

  @media(max-width: 1200px) {
    .click-for-sound-text {
        display: block;
    }

    .plyr__control--overlaid {
        border-width: 4px;
        height: 70px;
        width: 70px !important;
    }
  }

  @media(max-width: 767px) {
    .video-wrap video {
        height: auto;
    }

    .main-footer {
        font-size: 16px;
    }

    .markers-list ul li {
        padding: 6px 8px;
        font-size: 12px;
    }

    .video-wrap {
        width: 94%;
    }

    .plyr__control--overlaid {
        border-width: 3px;
        height: 30%;
        width: auto !important;
        aspect-ratio: 1/1;
    }

    .plyr__control--overlaid svg {
        width: 50%;
        height: 50%;
    }

    .click-for-sound {
        padding: 0px 11px;
        height: 36px;
        font-size: 11px;
        top: 5px;
        right: 7px;
    }

    .click-for-sound img {
        width: 18px;
    }

    .plyr__control svg {
        width: 50%;
        height: 50%;
        min-width: 14px;
        min-height: 14px;
    }

    .plyr__control img {
        width: 14px;
    }

    .plyr__controls__item .plyr__control,
    button.plyr__controls__item {
        height: 36px;
        width: 30px;
        padding: 2px;
    }

    .plyr--video .plyr__controls {
        height: 40px;
    }

    .speed-control-area-num .x-btn,
    .speed-control-area {
        font-size: 11px;
    }

    .speed-control-area-row {
        padding: 4px 9px;
    }

    .speed-control-area {
        bottom: 24%;
    }
  }
`;