import { Box, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import SectionHeader from "components/SectionHeader";
import ThreeColumnsGrid from "components/ThreeColumnsGrid";
import CardBox from "components/CardBox";
import BlogColumn from "components/BlogColumn";
import Img1 from "../../assets/img02.jpg"
import Img2 from "../../assets/img03.jpg"
import Img3 from "../../assets/img04.jpg"

const BlogsSection = ({pTop, pBottom}: any) => (
  <Section
    className="BlogsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        heading="Promote Financial Literacy & Get Paid"
        tagline="Case Studies"
      />
      <ThreeColumnsGrid>
        <CardBox>
          <BlogColumn imageUrl={Img1} heading="Reunion Gold" excerpt="“Since launching our new website, its always been up to date.”" />
        </CardBox>
        <CardBox>
          <BlogColumn imageUrl={Img2} heading="Fireweed Metals" excerpt="“The native integration with SEDAR and EDGAR make my life easy.”" />
        </CardBox>
        <CardBox>
          <BlogColumn imageUrl={Img3} heading="Cotec Holdings" excerpt="“Its been a joy switching to 6ix Websites.”" />
        </CardBox>
      </ThreeColumnsGrid>
    </Wrapper>
  </Section>
);

export default BlogsSection;