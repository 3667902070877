import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FieldArray, FormikTouched } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as Yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CourseBuilderBox, CustomCheckbox, CustomDivider, CustomFormControl, FormField, FormRow, FormWrapper, LabelHeading, MainHeading, RowHeading, TagsFormControl, TagsWrapper, TextAreaField, WidgetBox, WrapperBox } from "./style";
import { CheckBox } from "@mui/icons-material";
import { useState } from "react";
import { set } from "lodash";
import { Lesson } from "./Lesson";
import { Section } from "./Section";
import { UploadIcon } from "components/Icons/UploadIcon";

const CourseSechma = Yup.object().shape({
  slug: Yup.string().required("Slug is Required"),
  title: Yup.string().required(),
  description: Yup.string().required(),
  price: Yup.number(),
  tasks: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Section Name is Required"),
      topics: Yup.array().of(
        Yup.object().shape({
          slug: Yup.string().required("Lesson Slug is Required"),
          title: Yup.string().required("Lesson Title is Required"),
          video: Yup.string().required("Lesson Video is Required"),
          locked: Yup.boolean(),
        })
      ),
    })
  ),
});

export const CourseBuilder = ({
  submitHandler,
  initialData,
}: {
  submitHandler: any;
  initialData?: any;
}) => {
  const [purchase, setPurchase] = useState(initialData?.price ? true : false);

  return (
    <CourseBuilderBox className="CourseBuilderBox">
      <MainHeading
        variant="h1"
      >
        Create or Edit a Course
      </MainHeading>
      <Formik
        initialValues={
          initialData || {
            slug: "",
            img: "",
            title: "",
            price: "",
            description: "",
            document: "",
            tags: [],
            tasks: [
              {
                name: "",
                pos: 0,
                topics: [
                  {
                    pos: 0,
                    slug: "",
                    title: "",
                    video: "",
                  },
                ],
              },
            ],
          }
        }
        validationSchema={CourseSechma}
        onSubmit={(values) => submitHandler(values)}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
        }) => (
          <FormWrapper>
            {console.log(errors)}
            <Form>
              <WrapperBox>
                <RowHeading
                  variant="h2"
                >
                  Courses details:
                </RowHeading>
                <WidgetBox>
                  <FormRow>
                    <LabelHeading
                      variant="h3"
                    >
                      Course Name
                    </LabelHeading>
                    <FormField
                      name="title"
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue(
                          "slug",
                          e.target.value.replaceAll(" ", "-")
                        );
                      }}
                    />
                    {errors.title && touched.title ? (
                      <div>{errors.title}</div>
                    ) : null}
                  </FormRow>
                  <FormRow>
                    <LabelHeading
                      variant="h3"
                    >
                      Course Image URL
                    </LabelHeading>
                    <CustomFormControl>
                      <input
                        type="file"
                        name="img"
                        id="file-upload"
                        // set supported file types here,
                        // could also check again within formik validation or backend
                        accept="image/jpg, image/jpeg, image/png, .svg"
                        onChange={(e) => {
                          // Object is possibly null error w/o check
                          if (e.currentTarget.files) {
                            setFieldValue("img", e.currentTarget.files[0]);
                          }
                        }}
                      />
                      <label
                        htmlFor="file-upload"
                        className="custom-file-input"
                      >
                        <UploadIcon />
                        {values.img && (
                          <span>
                            {typeof values.img === "string"
                              ? "Upload image"
                              : URL.createObjectURL(values.img)}
                          </span>
                        )}
                      </label>
                    </CustomFormControl>
                    {errors.img && touched.img ? (
                      <div>{errors.img}</div>
                    ) : null}
                    {values.img && (
                      <img
                        src={
                          typeof values.img === "string"
                            ? values.img
                            : URL.createObjectURL(values.img)
                        }
                      />
                    )}
                  </FormRow>
                  <FormRow>
                    <LabelHeading
                      variant="h3"
                    >
                      Course Description
                    </LabelHeading>
                    <TextAreaField
                      name="description"
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue(
                          "description",
                          e.target.value
                        );
                      }}
                      multiline
                    />
                    {errors.description && touched.description ? (
                      <div>{errors.description}</div>
                    ) : null}
                  </FormRow>
                  <FormRow>
                    <LabelHeading
                      variant="h3"
                    >
                      Course Outline
                    </LabelHeading>
                    {/* 
                    // @ts-ignore */}
                    <CKEditor
                      editor={ClassicEditor}
                      data={values.document}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("document", data);
                      }}
                    />
                  </FormRow>
                  <FormRow>
                    <LabelHeading
                      variant="h3"
                    >
                      Tags
                    </LabelHeading>
                    <TagsFormControl>
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        options={[]}
                        freeSolo
                        renderTags={() => undefined}
                        onChange={(e: any) =>
                          setFieldValue("tags", [
                            ...values.tags,
                            e.target.value,
                          ])
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Enter Tags" />
                        )}
                      />
                      <TagsWrapper>
                        {values.tags?.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            deleteIcon={<CloseIcon />}
                            onDelete={(e) =>
                              setFieldValue(
                                "tags",
                                values.tags.filter(
                                  (tag: any, tagIndex: number) =>
                                    index !== tagIndex
                                )
                              )
                            }
                          />
                        ))}
                      </TagsWrapper>
                    </TagsFormControl>
                  </FormRow>
                  <FormRow>
                    <CustomCheckbox
                      control={
                        <Checkbox
                          defaultChecked
                          checked={purchase}
                          onChange={(e) => {
                            setPurchase((prev) => !prev);
                            if (!e.target.checked) {
                              setFieldValue("price", "");
                            }
                          }}
                        />
                      }
                      label="Paid Course?"
                    />
                  </FormRow>
                  {purchase && (
                    <FormRow>
                      <LabelHeading
                        variant="h3"
                      >
                        Price
                      </LabelHeading>
                      <FormField
                        name="price"
                      />
                      {errors.price && touched.price ? (
                        <div>{errors.price}</div>
                      ) : null}
                    </FormRow>
                  )}
                </WidgetBox>
              </WrapperBox>
              <CustomDivider />
              <Section purchase={purchase} />
            </Form>
          </FormWrapper>
        )}
      />
    </CourseBuilderBox>
  );
};
