import { styled, Box, Link } from '@mui/material';

export const SidebarWrapper = styled('div')`
  position: fixed;
  left: 0;
  top: 66px;
  bottom: 0;
  width: 320px;
  background-color: #131313;
  transform: translateX(-400px);
  transition: transform 0.3s ease;
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px){
    top: 67px;
  }

  .sidebar-wrapper-active &{
    transform: translateX(0);
  }

  .side-nav-list{
    padding: 10px;
  }

  .sbwListButton{
    padding: 12px;
    font-size: 15px;
    line-height: 135%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: rgb(192, 192, 192);

    &:hover{
      color: #e3c050;
      background-color: transparent;
    }

    > div{
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiListItemText-primary{
      font: inherit;
    }

    .navicon{
      position: absolute;
      left: 15px;
      top: calc(50% + 2px);
      transform: translateY(-50%);
    }
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border: 1px solid #2b2b2b;
  background-color: #1f1f1f;
  box-shadow: 0 4px 50px #131313;
  border-radius: 11px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ButtonWrapper = styled(Box)`
  padding: 16px;
  border-bottom: 1px solid #2b2b2b;
`;

export const LinkPrimary = styled(Link)`
  display: block;
  border-radius: 6px;
  background-color: #000;
  border: 1px solid #E3C050;
  color: #fff;
  padding: 13px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover{
    color: #fff;
    text-decoration: none;
    background-color: #3f3f3f;
  }

  svg{
    width: 20px;
    height: 20px;
  }
`;