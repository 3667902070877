/* global google */
import React, { ReactChild, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import avatar from "assets/avatar.png";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { Sidebar } from "components/Sidebar";
import { FilterbarWrapper } from "components/FilterBar/style";
import logo from "../../assets/logo.svg";
import { Box, Button } from "@mui/material";
import { NavWrapper, Separator, ToolbarWrapper, Wrapper, ButtonNav, Logo, ActionsWrapper, ProfileDropdown, LoginButton } from "./style";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import GridViewIcon from "@mui/icons-material/GridView";
import Divider from "@mui/material/Divider";
import { BellIcon } from "../Icons/BellIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithCredentialResponse, userLogout } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import { LinkAccount } from "./LinkAccount";
import AppsDropdown from "components/AppsDropdown";
import { CrossIcon } from "components/Icons/CrossIcon";
import SelectorDropdown from "components/SelectorDropdown";
import { UserIcon } from "components/Icons/UserIcon";
import LanguageDropdown from "components/LanguageDropdown";
import LanguageTranslate from "components/LanguageTranslate/LanguageTranslate";
import { BurgerMenuIcon } from "components/Icons/BurgerMenuIcon";

const HeaderNew = ({ children }: { children: ReactChild }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorAppEl, setAnchorAppEl] = React.useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [loginRequest, setLoginRequest] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState<any>("");

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAppsMenu = (event: any) => {
    setAnchorAppEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorAppEl(null);
    setAnchorEl(null);
  };

  const initializeGSI = () => {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: true,
      callback: loginWithWithCredential,
    });

    // @ts-ignore
    google.accounts.id.prompt();
  };

  const loginWithWithCredential = async (response: any) => {
    const data = await signInWithCredentialResponse(response);
    if (data) {
      setLoginRequest(false);
      navigate(0);
    }
  };

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  const LogutUSER = () => {
    userLogout();
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      const DATA = {
        // @ts-ignore
        tokenID: user.accessToken,
        name: user.displayName,
        email: user.email,
        profileImage: user.photoURL,
      };
      setUserInfo(DATA);
      localStorage.setItem("uid", user.uid);
    } else {
      initializeGSI();
    }
  }, [user, loading]);

  return (
    <>
      <Wrapper className={(sidebar && "sidebar-wrapper-active") || ""}>
        <NavWrapper>
          <ButtonNav
            className={
              !sidebar
                ? ""
                : "sidebar-opener--close"
            }
            onClick={() => setSidebar((prev) => !prev)}
          >
            <i className="icn-wrap">
              <BurgerMenuIcon />
            </i>
            <i className="icn-close">
              <CrossIcon />
            </i>
          </ButtonNav>
          <Separator />
          <Logo href="/">
            <img src={logo} alt="6ix Courses" width={'84'} height={'54'} />
          </Logo>
        </NavWrapper>
        <ActionsWrapper>
          {userInfo &&
            <>
              <SelectorDropdown label={userInfo.email} id="six" />
              <Separator />
            </>
          }
          <LanguageTranslate />
          <AppsDropdown label="APPS" id="apps" />
          {userInfo ? (
            <ProfileDropdown>
              <img src={userInfo.profileImage || avatar} alt="" />
            </ProfileDropdown>
            ) : !loading ? (
                <LoginButton
                  onClick={handleLogin}
                >
                  <i className="icn-wrap">
                    <UserIcon />
                  </i>
                  LOGIN
                </LoginButton>
            ) : null
          }
        </ActionsWrapper>
        <Sidebar setSidebar={setSidebar} />
      </Wrapper>
      <FilterbarWrapper className="FilterbarWrapper">
        {children}
      </FilterbarWrapper>
      {!userInfo && loginRequest ? (
        <LoginPopup handleLogin={handleLogin} />
      ) : null}
  </>
  );
};

export default HeaderNew;
