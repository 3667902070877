import SectionHeader from "components/SectionHeader";
import { Wrapper, Section, DescriptionText, PriceFeaturesList } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";
import PriceColumn from "components/PriceColumn";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { TickIcon } from "components/Icons/TickIcon";

const PricingSection = ({pTop, pBottom}: any) => (
  <Section
    className="PricingSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        extraClass="headingHead"
        heading="Pricing"
      >
        <DescriptionText
          variant="body1"
        >
          Get started for free & pay as you go
        </DescriptionText>
      </SectionHeader>
      <TwoColumnsGrid>
        <CardBox>
          <PriceColumn
            type="SILVER"
            excerpt="Create, monetize and promote your course"
            priceText="$ 0/mo"
            percentText="+ 10% per transaction"
            buttonLabel="GET STARTED"
          >
            <PriceFeaturesList>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Intuitive Course Creation Tools"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Integrated Monetization Gateways"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Embedded AI"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Access to new features"
                />
              </ListItem>
            </PriceFeaturesList>
          </PriceColumn>
        </CardBox>
        <CardBox>
          <PriceColumn
            type="GOLD"
            excerpt="Maximize outreach, engagement, & trust with a personal touch"
            priceText="Enterprise"
            buttonLabel="get in touch"
          >
            <PriceFeaturesList>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="A dedicated account manager for all your course needs."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Behind-the-scenes support for content updates, multimedia integrations, and more."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Proactive recommendations based on course analytics to enhance engagement."
                />
              </ListItem>
            </PriceFeaturesList>
          </PriceColumn>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default PricingSection;