import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const ListingSection = ({pTop, pBottom}: any) => (
  <Section
    className="ListingSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <CardBox>
        <FeaturesListColumn heading="6ix Courses is the only platform with..." hasBorder asIndividual>
          <FeaturesList>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Intuitive Course Creation Tools
              </Typography>
              <ListItemText
                primary="Whether you're a novice or a seasoned expert, our user-friendly interface streamlines the process of creating a course so that you can focus on promoting financial literacy."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Integrated Monetization Gateways
              </Typography>
              <ListItemText
                primary="No need for third-party apps or complex setups. Accept payments instantly while enjoying a smooth, hassle-free revenue stream."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Embedded AI
              </Typography>
              <ListItemText
                primary="Launch your course faster with AI-generated titles, descriptions, scripts, videos, images and other content recommendations. Provide a personalized, 1:1 tutor to each of your students, expertly trained on your course content. "
              />
            </ListItem>
          </FeaturesList>
        </FeaturesListColumn>
      </CardBox>
    </Wrapper>
  </Section>
);

export default ListingSection;