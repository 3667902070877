import { Box, Typography } from "@mui/material";
import CourseDropdown from "components/CourseDropdown";
import { Wrapper, Section, BgWrapper } from "./style";
import SectionMainHeader from "components/SectionMainHeader";
import ButtonsFooter from "components/ButtonsFooter";
import BgImage from "../../assets/bg-img01.jpg"
import LandingVideo from "components/LandingVideo";

const IntroSection = ({}: any) => (
  <Section className="IntroSection">
    <Wrapper>
      <CourseDropdown label="COURSES" id="intro-course" />
      <SectionMainHeader
        heading="Ready to promote financial literacy & get paid?"
        description="Promote financial literacy & get paid with 6ix Courses, the only financial literacy platform with intuitive course creation tools, integrated monetization gateways & embedded AI."
        size="900px"
      />
      <ButtonsFooter primaryLabel="GET IN TOUCH" secondaryLink="#" secondaryLabel="EXPLORE OR BUILD A COURSE" primaryLink="#" />
      <LandingVideo />
    </Wrapper>
    <BgWrapper>
      <img src={BgImage} alt="" />
    </BgWrapper>
  </Section>
);

export default IntroSection;