import { Wrapper, Section, DescriptionText, VideosWrapper, Col } from "./style";
import Image1 from "../../assets/img31.jpg"
import SectionHeader from "components/SectionHeader";
import FeaturedVideo from "components/FeaturedVideo";
import VideosList from "components/VideosList";

const VideosSection = ({pTop, pBottom}: any) => (
  <Section
    className="VideosSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        extraClass="headingHead"
        heading="Accelerate Your Career By Becoming a 6ix Websites Expert"
        tagline="Training"
      >
        <DescriptionText
          variant="body1"
        >
          Learn how to host investor events that reach more investors, attract capital and improve your company’s reputation.
        </DescriptionText>
      </SectionHeader>
      <VideosWrapper>
        <Col>
          <FeaturedVideo
            imageUrl={Image1}
            heading="How to Design & Build Your Course on 6ix"
            caption="Episode 1"
          />
        </Col>
        <Col>
          <VideosList />
        </Col>
      </VideosWrapper>
    </Wrapper>
  </Section>
);

export default VideosSection;