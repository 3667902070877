import { Box } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { AuthorizedRoutes } from "./AuthorizedRoutes";
import { UnauthorizedRoutes } from "./UnauthorizedRoutes";

export const AppRouter = () => {
  const [user] = useAuthState(auth)

  return (
    <Box>
      {user ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}
    </Box>
  );
};
