import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Anchor, ButtonDropdownWrapper, ButtonOpener, CourseMenu, DescriptionWrapper, DropdownFooter, DropdownHeader, SwitchEnabler, ButtonDrag, CaptionText, FootLink, IconWrapper, ItemHeading, MenuHeading, TextPara, AnchorLink, EditButton } from './style';
import { MonitorIcon } from 'components/Icons/MonitorIcon';
import GridViewIcon from "@mui/icons-material/GridView";
import { Box } from '@mui/material';
import { HomeIcon } from 'components/Icons/HomeIcon';
import { UnionIcon } from 'components/Icons/UnionIcon';
import { BookIcon } from 'components/Icons/BookIcon';
import { MegaPhoneIcon } from 'components/Icons/MegaPhoneIcon';
import { ShoppingBagIcon } from 'components/Icons/ShoppingBagIcon';
import { DragMoveIcon } from 'components/Icons/DragMoveIcon';
import { GlobeIcon } from 'components/Icons/GlobeIcon';
import { VidPlayIcon } from 'components/Icons/VidPlayIcon';
import { AppsIcon } from 'components/Icons/AppsIcon';

export default function AppsDropdown({label, id} : any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    // <ButtonDropdownWrapper>
    //   <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
    //     <i className='icn-wrap'>
    //       <GridViewIcon />
    //     </i>
    //     {label}
    //   </ButtonOpener>
    //   <CourseMenu id={`${label}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
    //     <MenuItem>
    //       <DropdownHeader>
    //         <MenuHeading
    //           variant="caption"
    //         >
    //           Favorite Apps
    //         </MenuHeading>
    //         {/* <CaptionText
    //           variant="caption"
    //         >
    //           Done
    //         </CaptionText> */}
    //         <EditButton>
    //           Edit
    //         </EditButton>
    //       </DropdownHeader>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <IconWrapper>
    //           <HomeIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Home
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Grow your net worth & market cap
    //           </TextPara>
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <MonitorIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Events
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Attend & host investor events
    //           </TextPara>
    //           <SwitchEnabler {...label} defaultChecked />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <UnionIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             AI
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Generate & analyze text, images and code
    //           </TextPara>
    //           <SwitchEnabler {...label} defaultChecked />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <MegaPhoneIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Promo
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Launch ad campaigns
    //           </TextPara>
    //           <SwitchEnabler {...label} defaultChecked />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <BookIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Courses
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Study & teach financial courses 
    //           </TextPara>
    //           <SwitchEnabler {...label} defaultChecked />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <VidPlayIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Videos
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Your video in every language
    //           </TextPara>
    //           <SwitchEnabler {...label} />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <Anchor>
    //         <ButtonDrag>
    //           <DragMoveIcon />
    //         </ButtonDrag>
    //         <IconWrapper>
    //           <GlobeIcon />
    //         </IconWrapper>
    //         <DescriptionWrapper className="DescriptionWrapper">
    //           <ItemHeading
    //             variant="caption"
    //           >
    //             Websites
    //           </ItemHeading>
    //           <TextPara
    //             variant="body1"
    //           >
    //             Your superpowered IR website
    //           </TextPara>
    //           <SwitchEnabler {...label} />
    //         </DescriptionWrapper>
    //       </Anchor>
    //     </MenuItem>
    //     <MenuItem>
    //       <DropdownFooter>
    //         <FootLink
    //           href="#"
    //         >
    //           <ShoppingBagIcon />
    //           Learn more about 6ixOS & App Store
    //         </FootLink>
    //       </DropdownFooter>
    //     </MenuItem>
    //   </CourseMenu>
    // </ButtonDropdownWrapper>
    <ButtonDropdownWrapper>
      <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        <i className='icn-wrap'>
          <AppsIcon />
        </i>
        {label}
      </ButtonOpener>
      <CourseMenu id={`${label}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <DropdownHeader>
            <MenuHeading
              variant="caption"
            >
              Favorite Apps
            </MenuHeading>
          </DropdownHeader>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://6ix.com/">
            <IconWrapper>
              <HomeIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Home
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Grow your net worth & market cap
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://events.6ix.com/">
            <IconWrapper>
              <MonitorIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Events
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Attend & host investor events
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://ai.6ix.com/">
            <IconWrapper>
              <UnionIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                AI
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Generate & analyze text, images and code
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://leads.6ix.com/">
            <IconWrapper>
              <MegaPhoneIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Leads
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Launch ad campaigns
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://courses.6ix.com/">
            <IconWrapper>
              <BookIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Courses
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Study & teach financial courses 
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://videos.6ix.com/">
            <IconWrapper>
              <VidPlayIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Videos
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Your video in every language
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
        <MenuItem>
          <AnchorLink href="https://websites.6ix.com/">
            <IconWrapper>
              <GlobeIcon />
            </IconWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <ItemHeading
                variant="caption"
              >
                Websites
              </ItemHeading>
              <TextPara
                variant="body1"
              >
                Your superpowered IR website
              </TextPara>
            </DescriptionWrapper>
          </AnchorLink>
        </MenuItem>
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}