import { Wrapper, Section, MainHeading, ImageWrapper } from "./style";
import RotaryCard from "components/RotaryCard";
import RotaryCardsWrapper from "components/RotaryCardsWrapper";
import IconImage1 from "../../assets/img-icon-01.svg"
import IconImage2 from "../../assets/img-icon-02.svg"
import IconImage3 from "../../assets/img-icon-03.svg"
import IconImage4 from "../../assets/img-icon-04.svg"
import IconImage5 from "../../assets/img-icon-05.svg"
import IconImage6 from "../../assets/img-icon-06.svg"
import IconImage7 from "../../assets/img001.png"

const ConditionsSection = ({pTop, pBottom}: any) => (
  <Section
    className="ConditionsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <MainHeading
        variant="h2"
      >
        Warning: You'll hate 6ix Courses if&hellip;
      </MainHeading>
      {/* <RotaryCardsWrapper>
        <RotaryCard
          heading=" "
          imageUrl={IconImage1}
          width="110"
          height="143"
        />
        <RotaryCard
          heading="You prefer complexity"
          imageUrl={IconImage2}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="You like hidden fees"
          imageUrl={IconImage3}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="You don’t want a new income stream"
          imageUrl={IconImage4}
          width="167"
          height="122"
        />
        <RotaryCard
          heading="You don’t believe in financial literacy"
          imageUrl={IconImage5}
          width="185"
          height="106"
        />
        <RotaryCard
          heading=" "
          imageUrl={IconImage6}
          width="165"
          height="159"
        />
      </RotaryCardsWrapper> */}
      <ImageWrapper>
        <img src={IconImage7} alt="" />
      </ImageWrapper>
    </Wrapper>
  </Section>
);

export default ConditionsSection;